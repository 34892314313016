.heading{
    padding-left: 20px;
    padding-bottom: 2px;
}

.line {
    content: '';
    display: block;
    width: 95%;
    height: 2px;
    background-color: #f0db7a;
    position: relative;
    /* bottom: -5px; */
    margin-bottom: 20px;
}

.category{
    padding: 20px;
    display: inline-block;
    margin: 20px;
    /* gap: 2rem; */
    /* margin-left: 50px; */
    /* margin-top: 15px; */
    transition: box-shadow 0.3s;
    border-radius: 10px;
    vertical-align: top;
    text-decoration: none;
}

.category:hover{
    box-shadow: 0 0 30px #f0db7a
}

.img{
    width: 270px;
    height: 330px;
}

.txt{
    color: #a6a6a6;
    width: 300px;
    font-family: 'Poppins', sans-serif;
}
