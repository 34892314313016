.btn-div{
    text-align: center;
    /* justify-content: center; */
    padding: 20px;
}
.video-button{
    background: linear-gradient(180deg,#d9a63d,#f0db7a);
    border: none;
    border-radius: 5px;
    color: black;
    height: 50px;
    width: 225px;
    text-align: center;
    /* text-decoration: none; */
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    padding-left: 30px;
}

.play-icon{
    height: 20px;
    display: inline-block;
    margin-left: 5px;
}

.download-btn {
    width: 225;
    height: 80px;
    text-align: left;
    padding: 10px;
    gap: 2rem;
}

.download-btn:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease;
}


.download-icon  {
    height: 35px;
}

