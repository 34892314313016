.header{
    background-color: #000;
    position: fixed !important;
    z-index: 1;
    width: 100%;
    padding: 0.5rem;
    top: 0;
}

.brand{
    color: #a6a6a6;
    font-family: 'Gotham';
    font-weight: bold;
    font-size: 35px;
    margin-top: 10px;
    margin-left: 25px;
    display: inline-block;
    text-decoration: none;
}
.sub-brand{
    font-size: 15px;
}
.logo{
    height: 40px;
    margin-left: 10px;
    margin-top: 7px;
}

.header-container{
    display: flex;
}

.sub-container{
    display: flex;
    flex-direction: row;
}

.navs{
    display: flex;
    flex-direction: row;
    margin-left: 25rem;
    gap: 1.5rem;
    
}

.sub-container-mob{
    display: flex;
    flex-direction: column;
}

.navs-mob{
    display: flex;
    /* flex-direction: row; */
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    gap: 1.5rem;

}

/* 
.nav-item{
    display: flex;
    padding-left: 20px;
    align-items: center;
    justify-items: center;
}

.icons{
    height: 20px;
    padding: 15px;
    justify-items: center;
}

.icon-text{
    color: #a6a6a6;
    font-weight: bold;
    font-size: 1.25rem;
    margin-top: 15px;
    text-decoration: none;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} */
.nav-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;                   
}

.icons {
    width: 1rem;
    height: auto;              
}

.icon-text {
    text-decoration: none;       
    color: #a6a6a6;             
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    /* font-family: Arial, Helvetica, sans-serif */
}