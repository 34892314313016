.container {
    position: relative;
    width: 100%; /* Make container full-width */
    max-width: 100%; /* Ensure no overflow */
    margin-top: 5rem;
}

.prod-banner-image {
    width: 100%; /* Make image responsive */
    height: auto;
    /* display: block; Remove bottom margin in some browsers */
}




.txt{
    color: #a6a6a6;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: black;
    display: inline-block;
    padding: 0.5rem;
    font-size: 2rem;
    font-weight: 700;
}

.prod-container{
    margin: 1rem;
    display: flex;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}
.prod-container:hover{
    box-shadow: 0 0 30px #f0db7a
}
.prod-image{
    height: 15rem;
    width: 25rem;
    object-fit: cover;
    padding: 1rem;
    /* justify-content: flex-end; */
}

.prod-image:hover{
    transform: scale(1.2); /* Zoom in by 20% */
    transition: transform 0.3s ease;
}

.prod-desc-container{
    /* display: flex; */
    padding-left: 2rem;
    
}
.details-link{
    display: block;
    padding-top: 1.5rem;
    font-size: 1.25rem;
    text-decoration: none;
}
.all-prod-container{
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.all-prods{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.all-prods:hover{
    text-decoration: underline;
    text-decoration-color: #a6a6a6;
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

.all-prod-arrow{
    width: 2.25rem;
    justify-items: center;
}


@media only screen and (min-width:375px) and (max-width:768px){
    .container{
        margin-top: 8rem;
    }
    .text-overlay{
    padding: 0.25rem;
    font-size: 0.75rem;
    }
    
}

@media (min-width: 768px) and (max-width: 1224px) {
    .text-overlay {
        font-size: 1.5rem; /* Adjust font size on smaller screens */
        /* width: 5rem; */
        /* padding: 1rem; */
    }
    .prod-image{
        height: 15rem;
        width: 20rem;
    }
    .container{
        margin-top: 8rem;
    }
}