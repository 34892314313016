.footer-container{
    padding: 1rem;
    opacity: 0.75;
    display: flex;
    gap: 5rem;
}

.footer-txt{
    /* color: black; */
    color: #a6a6a6;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}